<template>
  <div class="config-page" v-loading="loading">
    <reminder title="温馨提示" text="请完善组织信息"></reminder>

    <el-form
      size="medium"
      :model="configForm"
      :rules="rules"
      label-position="right"
      label-width="165px"
      class="small-form"
      ref="configForm"
      @submit.native.prevent
    >
      <div class="modular">
        <p class="modular-title">组织信息</p>
        <div class="modular-content" style="margin-left: 11px">
          <el-form-item label="组织LOGO：" prop="logo">
            <div class="input-item">
              <image-wall
                v-model="configForm.logo"
                fit="contain"
                :width="194"
                :height="135"
                ratio="136:136"
              >
                <p slot="info" class="img-tips" style="margin-top: 8px">
                  <span class="el-icon-info"></span>建议上传尺寸比例为300px*300px,不上传图片时，默认使用系统自带图片
                </p>
              </image-wall>
              <el-input v-show="false" v-model="configForm.logo"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="组织名称：" prop="name">
            <el-input
              v-model="configForm.name"
              placeholder="请填写组织名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="平台码：" prop="code">
            <span>{{ configForm.code }}</span>
            <el-link
              :style="{
                marginLeft: '5px',
              }"
              type="primary"
              @click="copy(configForm.code)"
              :underline="false"
              >复制</el-link
            >
          </el-form-item>
          <el-form-item label="现任会长名称：" prop="president_name">
            <el-input
              v-model="configForm.president_name"
              placeholder="请填写现任会长名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="成立时间：" prop="create_time">
            <date-picker
              v-model="configForm.create_time"
              placeholder="请选择成立时间"
            />
          </el-form-item>
          <el-form-item label="资金规模：" prop="funds">
            <el-select v-model="configForm.funds" placeholder="请选择资金规模">
              <el-option
                v-for="item in fundsOptions"
                :label="item.label"
                :value="item.value"
                :key="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="地区：" prop="address">
            <el-cascader
              :options="region_data"
              v-model="configForm.address"
              :props="optionProp"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="详细地址：" prop="">
            <el-input
              v-model="configForm.detail_address"
              placeholder="请输入"
            />
          </el-form-item>
          <el-form-item label="组织标识：" prop="type">

    
            <el-select disabled v-model="configForm.type"  v-if="configForm.type === 5" placeholder="请选择组织标识">
              <el-option
                label="工商业联合会"
                :value="5"
                :key="5"
              ></el-option>
           
            </el-select>
            <el-select v-else v-model="configForm.type" placeholder="请选择组织标识">
              <el-option
                v-for="item in typeOptions"
                :label="item.label"
                :value="item.value"
                :key="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="社会团体法人登记证书：" prop="register_cert">
            <div class="input-item">
              <image-wall
                v-model="configForm.register_cert"
                :width="194"
                :height="135"
              >
                <!-- <p slot="info" class="img-tips" style="margin-top: 8px">
                  <span class="el-icon-info"></span>建议上传尺寸比例为500*400,不上传图片时，默认使用系统自带图片
                </p> -->
              </image-wall>
              <el-input
                v-show="false"
                v-model="configForm.register_cert"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="简介：" prop="description">
            <el-input
              type="textarea"
              :rows="6"
              v-model="configForm.description"
              placeholder="请填写"
            />
          </el-form-item>
          <el-form-item label="核心成员：">
            <MemberTable v-model="configForm.main_member"></MemberTable>
          </el-form-item>
          <el-form-item label="荣誉墙：">
            <HonourTable v-model="configForm.honours"></HonourTable>
          </el-form-item>
          <el-form-item label="背景颜色：">
            <!-- 颜色选择器 -->
            <el-color-picker v-model="configForm.color_code"></el-color-picker>
          </el-form-item>
          <el-form-item label="顶部图片：">
            <SingleMediaWall
              v-model="configForm.bg_image"
              :width="375"
              :height="280"
              raitio="375:280"
            >
              <template #info>
                <div class="info">
                  <i class="el-icon-info"></i>建议尺寸为375*280px
                </div>
              </template>
            </SingleMediaWall>
          </el-form-item>
        </div>
      </div>
    </el-form>

    <PhoneViewer v-model="openPreviewPhone" :form="configForm"></PhoneViewer>
    <FixedActionBar>
      <el-button type="warning" size="medium" @click="previewPhone"
        >预览界面
      </el-button>
      <el-button
        type="primary"
        size="medium"
        @click="formSubmit('configForm')"
        :loading="saveLoading"
        >保存
      </el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import { getProjectInfo, saveProjectInfo } from "../api/message-config";
import Reminder from "../../common/components/Reminder";
import ImageWall from "../../common/components/SingleMediaWall.vue";
import FixedActionBar from "@/base/layout/FixedActionBar";
import DatePicker from "@/base/components/Form/DatePicker";
import MemberTable from "../components/MemberTable";
import HonourTable from "../components/HonourTable";
import SingleMediaWall from "@/modules/common/components/SingleMediaWall";
import PhoneViewer from "../components/PhoneViewer";
import { copyText } from "@/base/utils/tool";

const regionData = require("@/modules/common/json/address.json");
export default {
  components: {
    FixedActionBar,
    ImageWall,
    Reminder,
    DatePicker,
    MemberTable,
    HonourTable,
    SingleMediaWall,
    PhoneViewer,
  },

  data() {
    return {
      loading: false, //加载
      saveLoading: false, //保存加载
      // 打开协会主页预览
      openPreviewPhone: false,
      configForm: {
        logo: "",
        name: "",
        president_name: "",
        funds: "", // 资金规模（0 未设置，10：10万以下，50：10～50万，100：50～100万 ）
        address: "", // 地区
        type: 0, // 组织标识（0：未选择，1：志愿者服务组织，2：行业协会商会，3：慈善组织，4：公开募捐资格）
        register_cert: "", // 登记证书
        create_time: "", // 成立时间
        detail_address: "", // 详细地址
        description: "", // 简介,
        main_member: [], // 主页成员
        honours: [], // 商会荣誉
        bg_image: "", // 顶部图片
      },
      rules: {
        logo: [
          { required: true, message: "请上传组织LOGO", trigger: "change" },
        ],
        name: [{ required: true, message: "请输入组织名称", trigger: "blur" }],
        description: [{ required: true, message: "请输入组织简介", trigger: "blur" }],
        funds: [
          { required: true, message: "请选择资金规模", trigger: "change" },
        ],
        create_time: [
          { required: true, message: "请选择成立时间", trigger: "change" },
        ],
        address: [{ required: true, message: "请选择地区", trigger: "change" }],
        type: [
          { required: true, message: "请选择组织标识", trigger: "change" },
        ],
        register_cert: [
          { required: true, message: "请上传登记证书", trigger: "change" },
        ],
      },
      optionProp: {
        expandTrigger: "hover",
        label: "label",
        value: "label",
        checkStrictly: true
      },
      fundsOptions: [
        { label: "未设置", value: 0 },
        { label: "10万以下", value: 10 },
        { label: "10 ~ 50万", value: 50 },
        { label: "50 ~ 100万", value: 100 },
      ],
      typeOptions: [
        { label: "未选择", value: 0 },
        { label: "志愿者服务组织", value: 1 },
        { label: "行业协会商会", value: 2 },
        { label: "慈善组织", value: 3 },
        { label: "公开募捐资格", value: 4 },
      ],
      region_data: regionData,
    };
  },
  methods: {
    // 获取精神列表
    getDetail() {
      this.loading = true;
      getProjectInfo()
        .then((res) => {
          const { data } = res;
          this.configForm = data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 保存
    formSubmit(keyWords) {
      this.$refs[keyWords].validate((valid) => {
        if (valid) {
          const data = { config: { ...this.configForm } };
          this.saveLoading = true;
          saveProjectInfo(data)
            .then((res) => {
              this.$message.success(res.msg);
              this.getDetail();
              this.saveLoading = false;
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          setTimeout(() => {
            var isError = document.getElementsByClassName("is-error");
            isError[0].scrollIntoView({ block: "center", behavior: "smooth" });
          }, 100);
          this.$message.error("请填写完整信息");
          return false;
        }
      });
    },
    // 手机预览协会主页效果
    previewPhone() {
      this.openPreviewPhone = true;
    },
    // 复制
    copy(text) {
      copyText(text).then((valid) => {
        this.$message.success("复制成功");
      });
    },
  },
  created() {
    this.getDetail(); //获取列表数据
  },
};
</script>

<style lang="scss" scoped>
.img-tips {
  font-size: 12px;
  color: #c0c4cc;
  line-height: 15px;
}
</style>

<template>
  <el-date-picker
    :type="type"
    :placeholder="placeholder"
    v-model="dateVal"
    style="width: 100%;"
    :format="format"
    :value-format="valueFormat"
    time-arrow-control
    :disabled="disabled"
  ></el-date-picker>
</template>
<script>
export default {
  model: {
    prop: "date",
    event: "handleDate",
  },
  props: {
    //类型
    type: {
      default: "date",
    },
    // 提示文字
    placeholder: {},
    // 绑定的变量 v-model
    date: {
      type: [Number, String],
      required: true,
    },
    // 指定输入框的格式
    format: {
      default: function () {
        switch (this.type) {
          case "year":
            return "yyyy";
          case "month":
            return "yyyy-MM";
          case "week":
            return "yyyy 第 W 周";
          default:
            return "yyyy-MM-dd";
        }
      },
    },
    //指定绑定值的格式
    valueFormat: {
      default: "timestamp",
    },
    disabled: Boolean
  },
  computed: {
    dateVal: {
      get() {
        let val = "";
        if (!this.date || this.date < 0) {
          return "";
        } else {
          val = this.date * 1000;
        }
        return val;
      },
      set(val) {
        let timestamp = val;
        if (this.valueFormat === "timestamp") {
          if (timestamp) {
            timestamp = timestamp / 1000;
          } else {
            timestamp = -1;
          }
        }
        this.$emit("handleDate", timestamp);
      },
    },
  },
};
</script>

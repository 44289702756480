<template>
  <el-dialog
    :visible="value"
    class="article-viewer dialog-vertical"
    close-on-click-modal
    close-on-press-escape
    width="380px"
    :show-close="false"
    @close="close"
  >
    <Phone>
      <div
        class="association-box"
        :style="{
          '--bg-color': form.color_code || '#51210E',
        }"
      >
        <!-- 顶部图片 -->
        <div class="banner fixed-center">
          <div class="bg-box">
            <img class="bg" v-if="form.bg_image" :src="form.bg_image" />
            <img
              class="bg"
              v-else
       
              :src="`${baseImgUrl}/organization-message/unit_default_image.png`"
            />
            <div class="transparent-linear-gradient"></div>
            <div class="association-info">
              <div class="top">
                <div class="icon">
                  <img :src="form.logo" />
                </div>
                <div class="info">
                  <div class="detail">
                    <div class="name">{{ form.name }}</div>
                    <div class="go">前往</div>
                  </div>
                  <div class="score">
                    <template>
                      <template v-for="i in 5">
                        <img class="star" src="../assets/images/star.png" />
                      </template>
                    </template>
                    <!-- <img
                      class="score-img"
                      v-else
                      :src="require('../static/images/five-star.png')"
                    ></img> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 商会简介 -->
        <div class="association-desc">
          <span
            class="desc"
            v-if="form.description && form.description.length > 50"
          >
            {{ form.description.substring(0, 50) }}...
            <span
              class="more"
              :style="{
                marginLeft: form.description.length ? '20px' : '0',
              }"
              >[展开]</span
            >
          </span>
          <span class="desc" v-else>
            {{ form.description }}
            <span
              class="more"
              :style="{
                marginLeft: form.description.length ? '20px' : '0',
              }"
              >[展开]</span
            >
          </span>
        </div>
        <!-- 核心成员 -->
        <div
          class="core-member"
          v-if="form.main_member && form.main_member.length"
        >
          <div class="title">核心成员</div>

          <el-scrollbar style="width: 100%;overflow-y:hidden;">
            <div class="item-box">
              <div class="item" v-for="(item, i) in form.main_member">
                <div class="item-content">
                  <div class="left">
                    <img :src="item.image" style="object-fit: cover" />
                  </div>
                  <div class="right">
                    <div class="name">
                      {{ item.name }} <span>{{ item.position }}</span>
                    </div>
                    <span class="title-name">{{ item.description }}</span>
                  </div>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
        <!-- 商会荣誉 -->
        <div class="honor" v-if="form.honours && form.honours.length">
          <div class="title">商会荣誉</div>

          <el-scrollbar style="width: 100%;overflow-y: hidden">
            <div class="item-box">
              <div class="item" v-for="item in form.honours">
                <div class="item-content">
                  <img :src="item.image" />
                  <div class="tag">
                    <div class="name">
                      {{ item.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
        <!-- 导航 假的 -->
        <div class="nav-station">
          <img class="bar-bg" src="../assets/images/nav-bar.png" />
        </div>
      </div>
    </Phone>
  </el-dialog>
</template>

<script>
import Phone from "@/base/components/Preview/Phone";

export default {
  name: "PhoneViewer",
  components: { Phone },
  model: {
    prop: "value",
    event: "changeValue",
  },
  props: {
    // 显示隐藏弹窗
    value: {
      type: Boolean,
      required: true,
    },
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      myOptions: {},
      baseImgUrl:''
    };
  },
  watch: {
    value(val) {},
  },
  computed: {},
  created() {
    this.baseImgUrl = window.serverConfig.VUE_APP_IMG_URL
  },
  methods: {
    close() {
      console.log(123);
      this.$emit("changeValue", false);
    },
  },
};
</script>

<style lang="scss">
.article-viewer {
  /* Chrome */
  &::v-deep.el-dialog {
    background: unset;
    box-shadow: unset;

    .el-dialog__header,
    .el-dialog__body {
      padding: 0;
    }
  }

  /* FireFox */
  .el-dialog {
    background: unset;
    box-shadow: unset;

    .el-dialog__header,
    .el-dialog__body {
      padding: 0;
    }
  }

  .page-content {
    padding-left: 14px;
    min-height: 92%;

    .top-content {
      padding-bottom: 20px;
      box-shadow: 0 1px 4px 0 #f2f2f2;

      .title {
        font-size: 20px;
        font-weight: bold;
        line-height: 28px;
        color: #191e29;
        text-align: justify;
      }

      .info {
        margin-top: 16px;
        font-size: 12px;
        color: #8c8e91;
        line-height: 12px;

        .divider {
          margin: 0 9px;
        }
      }
    }

    .summary {
    }

    .content {
      margin-top: 25px;
      padding-bottom: 50px;
    }
  }
  .max-width {
    width: 100% !important;
    height: auto !important;
  }
}

.association-box {
  background: var(--bg-color);
  // padding-bottom: 20px;
  .banner {
    width: 100%;
    height: 355px;
    position: relative;
    .bg-box {
      position: relative;
      .bg {
        width: 100%;
        height: 355px;
        object-fit: cover;
      }
      .transparent-linear-gradient {
        width: 100%;
        height: 280px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          var(--bg-color) 85%,
          var(--bg-color) 100%
        );
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 3;
      }
      .association-info {
        // @include maxwidth();
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 8px 16px;
        z-index: 4;
        .top {
          display: flex;
          align-items: center;
          .icon {
            width: 68px;
            img {
              width: 68px;
              height: 68px;
              border-radius: 5px;
              background-color: #fff;
            }
          }

          .info {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 12px;

            .detail {
              display: flex;
              justify-content: space-between;
              .name {
                font-size: 18px;
                color: #fff;
                font-weight: 500;
              }

              .go {
                font-size: 14px;
                font-weight: 500;
                color: var(--bg-color);
                padding: 3px 8px;
                background-color: #fff;
                border-radius: 22px;
              }
            }

            .score {
              margin-top: 8px;
              .star {
                width: 16px;
                height: 16px;
              }
              .star + .star {
                margin-left: 8px;
              }
              img.score-img {
                width: 62px;
                height: 17px;
                box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px;
              }
            }
          }
        }
      }
    }
  }
  // 商会简介
  .association-desc {
    padding: 0 16px;
    margin-top: 12px;
    margin-bottom: 16px;
    .desc {
      color: rgba(255, 255, 255, 0.6);
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      @include clamp(2);

      .more {
        font-size: 12px;
        color: #fff;
      }
    }
  }
  // 核心成员
  .core-member {
    .title {
      font-size: 14px;
      color: #fff;
      font-weight: 500;
      padding: 0 16px;
    }

    .item-box {
      white-space: nowrap;
      margin-top: 12px;

      .item {
        display: inline-block;
        height: 105px;
        width: 268px;
        padding: 12px;
        background-color: #fff;
        margin-left: 8px;
        border-radius: 4px;

        .item-content {
          display: flex;
          align-items: center;
          height: 100%;
          .left {
            width: 60px;
            height: 100%;
            img {
              width: 100%;
              height: 100%;
              border-radius: 4px;
            }
          }

          .right {
            flex: 1;
            margin-left: 8px;
            height: 100%;
            .name {
              color: #1a1a1a;
              font-size: 14px;
              font-weight: 500;
              span {
                font-size: 12px;
                color: #b3b3b3;
                font-weight: 400;
                margin-left: 4px;
              }
            }

            .title-name {
              color: #808080;
              font-size: 12px;
              font-weight: 400;
              white-space: normal;
              @include clamp(3);
              line-height: 18px;
            }
          }
        }
      }
      .item:first-child {
        margin-left: 16px;
      }
      .item:last-child {
        margin-right: 16px;
      }
    }
  }
  // 商会荣誉
  .honor {
    margin: 16px 0;

    .title {
      font-size: 14px;
      color: #fff;
      font-weight: 500;
      padding: 0 16px;
    }

    .item-box {
      margin-top: 12px;
      white-space: nowrap;

      .item {
        display: inline-block;
        height: 165px;

        width: 150px;
        border-radius: 6px;

        background-color: #fff;
        margin-left: 8px;
        overflow: hidden;
        .item-content {
          display: flex;
          flex-direction: column;
          // justify-content: center;
          height: 100%;
          border-radius: 6px;
          img {
            width: 100%;
            height: 110px;
          }
          .tag {
            font-size: 14px;
            padding: 12px;
            white-space: normal;
            margin-top: -10px;

            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            color: #1a1a1a;
            line-height: 21px;
            background-color: #fff;
            border-radius: 6px;
            height: 66px;
            .name {
              @include clamp(2);
            }
          }
        }
      }
      .item:first-child {
        margin-left: 16px;
      }
      .item:last-child {
        margin-right: 16px;
      }
    }
  }
  .nav-station {
    margin-top: 12px;
    padding: 5px 0px 5px;
    border-radius: 8px 8px 0 0;
    background-color: #fff;
    overflow: hidden;
    width: 100%;
    .bar-bg {
      height: auto;
      width: 100%;
    }
  }
}
</style>

<style scoped>
>>> .rich_media_content * {
  max-width: 100% !important;
  box-sizing: border-box !important;
  word-wrap: break-word !important;
}

>>> .rich_media_content {
  padding: 0;
  margin: 0;
  /*font-size:0;*/
  font-size: 17px;
}

>>> .autoTypeSetting24psection > section,
>>> .autoTypeSetting24psection > p {
  margin-top: 0;
  margin-bottom: 24px;
}

>>> .rich_media_content .h5_image_link {
  line-height: 0;
  font-size: 0;
  display: inline-block;
  position: relative;
  vertical-align: bottom;
  user-select: none;
}
</style>
